import { Component, OnInit, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from './translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
// import { type } from 'os';
import { Plugins } from '@capacitor/core';
// import {url} from 'src/url/config.json';
// import { Url } from 'url';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { NgNavigatorShareService } from 'ng-navigator-share';
import { ActivatedRoute } from "@angular/router";
import { JsonCommanObjectService } from 'src/services/json-comman-object.service.service';
import { url } from 'src/commonurl/commonurl';
const params = new URLSearchParams(window.location.search);
const referralId = params.get("invite");

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private ngNavigatorShareService: NgNavigatorShareService;
  selectedLanguage: string;
  public jwt: boolean;
  public userNumber: string;
  private finalObj;
  public prospectObj;
  public mainObject;
  public otherObject;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private translateConfigService: TranslateConfigService,
    public translate: TranslateService,
    private router: Router,
    public alertController: AlertController,
    ngNavigatorShareService: NgNavigatorShareService,
    private route: ActivatedRoute,
    private obj: JsonCommanObjectService,
    private http: HttpClient
    // private url: url
  ) {
    this.initializeApp();
    // this.presentAlertConfirm();
    this.selectedLanguage = this.translateConfigService.getDefaultLanguage();
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.finalObj = this.obj.quteData();
    this.prospectObj = this.obj.prospectObj;
    this.mainObject = this.obj.customerDetails();
    this.otherObject= this.obj.quteData();
  }





  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
    
  }

  openEnd() {
    this.menu.open('end');
    
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
    
  }
  menuOpened() {
    
    if (window.localStorage && window.localStorage.getItem('jwt') && window.localStorage.getItem('userNumber') ) {
      this.jwt = true;
      this.userNumber = window.localStorage.getItem('userNumber');
    } else {
      this.jwt = false;
      this.userNumber = 'Login';
    }
  }
  // languageChanged() {
  //   this.translateConfigService.setLanguage(this.selectedLanguage);
  // }
  initiateProspectsRequest (){
    if(referralId){
      this.prospectObj[`source`]
      this.http.patch(url.baseurl+`/api/leads/${referralId}/append`, { ip: this.prospectObj.ip, source: this.prospectObj.source, url : this.prospectObj.url  }).subscribe(
        (response) => {
          let prospectId = response[`updatedProspect`]._id
          let referrer = JSON.stringify(response[`updatedProspect`].referrer);
          
          localStorage.setItem("prospectId",  prospectId);
          localStorage.setItem("referrer", referrer);
          // this.router.navigate(["/first-splash"]);

          // this.router.navigate(["/apply"]);
        },
        (error) => {
          
        }
      );
    }
     else{
    this.http.post(url.baseurl+`/api/leads/add`, { ip: this.prospectObj.ip, source: this.prospectObj.source }).subscribe(
      (response) => {
        // console.log("Prospect Response : ",response[`data`][`leadId`])
        localStorage.setItem("prospectId", response[`data`][`leadId`]);
      },
      (error) => {
        
      }
     );
    }
  }
  
  
  
  // getUerIpAddress () {
  //   this.http.get('https://jsonip.com/').subscribe(data => {
  //     this.prospectObj.ip= data['ip'];
  //     this.mainObject.ip = data['ip'];
  //     this.otherObject.quoteData.ip=data['ip'];
  //     // console.log(this.prospectObj);
  //     // console.log("getIpAddress function calling")
  //       // this.finalObj.ip = data['ip'];
  //       // this.prospectObj.ip = data['ip'];
  //       // this.setUrlParamsIntoProspectObject();
  //       this.initiateProspectsRequest();
  //     });
  // }
  ngOnInit() {

    this.http.get('https://jsonip.com/').subscribe(data => {
      
      this.prospectObj.ip= data['ip'];
      this.mainObject.ip = data['ip'];
      this.otherObject.quoteData.ip=data['ip'];
      
      this.getQueryParams();
      // this.initiateProspectsRequest ()
    })
   
  }
  getQueryParams(){
    var value;
    var language;
    var param={};
    this.route.queryParams.subscribe(params => {
      
      value = params['key'];
      language=params['lang'];
      
      param = params;
      // if(!params.hasOwnProperty('errors')){
        // console.log('**',Object.keys(params))
        
      // }
     
    })
    // console.log(param);
    setTimeout(()=>{
      this.redirectUrl(language,value,param);
    },2000)
   

  }

  redirectUrl(language,value,params){
    
    // if(language){
    //   this.prospectObj.url.lang = language;
    // }if(value){
    //   this.prospectObj.url.key = value;
    // }
    // console
    this.prospectObj.url= params;
    this.initiateProspectsRequest ();
    // this.selectedLanguage = language;
    if(language == 'sp' && value){
      this.translateConfigService.setLanguage("sp");
    }else if(language == 'en' && value){
      this.translateConfigService.setLanguage("en");
    } else if(language == 'sp' && !value){
      this.translateConfigService.setLanguage("sp");
      this.router.navigate(["/insurance-type"],{ queryParams: {lang : language}});
      // this.router.navigateByUrl('/insurance-type');
    } else if(language == 'en' && !value){
      this.translateConfigService.setLanguage("en");
      this.router.navigate(["/insurance-type"],{ queryParams: {lang : language}});
      // this.router.navigateByUrl('/insurance-type');
    }
    if(value == 'life'){
      
      this.finalObj.quoteData.quoteType= 'LIFE';
      this.prospectObj.prospectType='LIFE'
      this.router.navigate(["/other-primary-name"],{ queryParams: {...this.prospectObj.url}});

      // this.router.navigateByUrl('/other-primary-name');
    } else if(value == 'commercial'){
      this.finalObj.quoteData.quoteType= 'COMMERCIAL';
      this.prospectObj.prospectType='COMMERCIAL'
      this.router.navigate(["/other-primary-name"],{ queryParams: {...this.prospectObj.url}});
      // this.router.navigateByUrl('/other-primary-name');
    } else if(value == 'motorcycle'){
      this.prospectObj.prospectType='MOTORCYCLE'
      this.finalObj.quoteData.quoteType= 'MOTORCYCLE';
      this.router.navigate(["/other-primary-name"],{ queryParams: {...this.prospectObj.url}});
      // this.router.navigateByUrl('/other-primary-name');
    } else if(value == 'home'){
      this.finalObj.quoteData.quoteType= 'HOME';
      this.prospectObj.prospectType='HOME'
      this.router.navigate(["/other-primary-name"],{ queryParams: {...this.prospectObj.url}});
      // this.router.navigateByUrl('/other-primary-name');
    } else if(value == 'renters'){
      this.finalObj.quoteData.quoteType= 'RENTERS';
      this.prospectObj.prospectType='RENTERS'
      this.router.navigate(["/other-primary-name"],{ queryParams: {...this.prospectObj.url}});
      // this.router.navigateByUrl('/other-primary-name');
    } else if(value == 'boat'){
      this.prospectObj.prospectType='BOAT'
      this.finalObj.quoteData.quoteType= 'BOAT';
      this.router.navigate(["/other-primary-name"],{ queryParams: {...this.prospectObj.url}});
      // this.router.navigateByUrl('/other-primary-name');
    } else if(value == 'auto'){
      this.prospectObj.prospectType='AUTO'
      this.finalObj.quoteData.quoteType= 'AUTO';
      this.router.navigate(["/first-splash"],{ queryParams: {...this.prospectObj.url}});
      // this.router.navigateByUrl('/first-splash');
    } else if(value == ''){
      if(window.localStorage && window.localStorage.getItem('isIntroDone') && window.localStorage.getItem('language')){
        const language = window.localStorage.getItem('language');
        this.translateConfigService.setLanguage(language);
        this.router.navigateByUrl('/insurance-type');
        window.localStorage.setItem('firstRunFinished', 'true');
      }
    }
  }
  
  toggleMenu() {
    this.menu.toggle(); //Add this method to your button click function
  }
  helpCenter(){
    this.toggleMenu();
    window.location.href="https://www.tidio.com/talk/fefv4irzl5mm0eropcqgncfpiiuj32mp";
  }

  async shareApi() {
    
    try{
      const sharedResponse = await this.ngNavigatorShareService.share({
        title:"Veronica's Insurance",
        text: "Hi, \n Veronica's app makes saving money on insurance easy. It take the confusion out of shopping for insurance by doing the work for you. Veronica's checks over 30 carriers to find you the best rates for the coverage you need.\n I highly recommend you to take a real time quote by visiting ",
        url: ' https://veronicasquote.com'
      });
      
    } catch(error) {
      
    }
    
  }

  // async presentAlertConfirm() {
  //   const alert = await this.alertController.create({
  //     header: 'Language',
  //     message: 'Please Select a Language',
  //     buttons: [
  //       {
  //         text: 'English',
  //         handler: () => {
  //           this.selectedLanguage = 'en';
  //           this.translateConfigService.setLanguage(this.selectedLanguage);
  //         }
  //       },
  //       {
  //         text: 'Spanish',
  //         handler: () => {
  //           this.selectedLanguage = 'sp';
  //           this.translateConfigService.setLanguage(this.selectedLanguage);
  //           // console.log(Language.value);
  //         }
  //       }
  //     ],
  //     backdropDismiss: false
  //   });

  //   await alert.present();
  // }
}
