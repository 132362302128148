import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonCommanObjectService {
  // public customerObj = {};
  public driver = 0;
  public carsIndex = -1;
  public customerObj = {
    "customer": {
        "customerData": {
            "firstName": "",
            "lastName": "",
            "gender": "",
            "dob": "",
            "maritalStatus": "",
            "homeOwner": "",
            "education": "",
            "isEmployed": "",
            "address":{
              "street":"",
              "city":"",
              "state": "",
              "zip": ""
            },
            "email": "",
            "phone" : "",
            "preferredContactMethods":""
        },
        "customerIncidents": []
    },
    "cars": [],
    "drivers": [],
    "ip":"",
    "ref" : {"fullName": '', "phoneNo" : ''},
    "quoteSource": 'PWA',
    "quoteType": "AUTO",
    "inputMethod" : ""
  };

  public customerObj2 = {
    "quoteData": {
        "customer": {
            "customerData": {
                "firstName": "",
                "lastName": "",
                "address": {
                    "street": "",
                    "city": "",
                    "state": "",
                    "zip": ""
                },
                
                "phone": "",
                "preferredContactMethods":""
            }
        },
        "quoteSource": "PWA",
        "quoteType": "",
        "ip":""
    }
  };

  public driverObjTemplate = {
    "driverData": {
      "firstName": "",
      "lastName": "",
      "gender":"",
      "dob": "",
      "education": "",
      "isEmployed": "",
      "relation": "",
    },
    "driverIncidents": []
  };
  public carObjTemplate = {
      "make": "",
      "model": "",
      "year": "",
      "vin":"",
      "financeMode": "",
      "dailyMileage":"",
      "additionalCover":[]
  };

  public prospectObj = {
    "firstName": "",
    "lastName": "",
    "address": {
      "street": "",
      "city": "",
      "state": "",
      "zip": ""
    },
    "phone" : "",
    "email" : "",
    "ip" : "",
    "source" : "PWA",
    "prospectType" : "",
    "url" : {}
  }

  public quotes = {};

  //Sanan bahi new objects 
  // PROSPECT SINGLE DRIVER
  public prospectSingleDriver = {
    firstName: "",
    lastName: "",
    relation: "",
    education: "",
    isEmployed: "",
    dob: "",
    gender: "",
  };

  // PROSPECT SINGLE CAR DETAIL
  public prospectSingleCar = {
    make: "",
    model: "",
    year: "",
    vin: "",
    financeMode: "",
    dailyMileage: "",
    additionalCover:[]
  };

  customerDetails() {
    return this.customerObj;
  }
  numberOfDrivers() {
    return this.driver;
  }
  singleDriverData() {
    return this.driverObjTemplate;
  }
  singleCarData() {
    return this.carObjTemplate;
  }
  quteData(){
    return this.customerObj2;
  }
  
}


